import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta'
import authMiddleware from "../middleware";

Vue.use(VueRouter)
Vue.use(Meta)

const router = new VueRouter({
    mode: 'history',
    base: "/",
    routes: [
        {
            path: '/',
            name: 'HomePage',
            component: () => import("@/components/pagina/homepage"),
            meta: {
                title: 'Home - Tunay',
                favicon: 'https://www.tunay.app/favicon.ico'
            }
        },

        {
            path: '/como-funciona',
            name: 'ComoFunciona',
            component: () => import("@/components/pagina/comofunciona"),
            meta: {
                title: 'Como Funciona - Tunay',
                favicon: 'https://www.tunay.app/favicon.ico'
            }
        },
        {
            path: '/fbdelete',
            name: 'EliminarFacebook',
            component: () => import("@/components/pagina/eliminarfacebook"),

        },
        {
            path: '/baresydiscos',
            name: 'baresydiscos',
            component: () => import("@/components/pagina/discosybares.vue"),
            meta: {
                title: 'Discos y Bares - Tunay',
                favicon: 'https://www.tunay.app/favicon.ico'
            }
        },
        {
            path: '/perfil',
            name: 'PerfilUsuario',
            component: () => import("@/components/clientes/perfil.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout',
                title: 'Discos y Bares - Tunay',
                favicon: 'https://www.tunay.app/favicon.ico'
            }
        },
        {
            path: '/password',
            name: 'PasswordPerfil',
            component: () => import("@/components/clientes/password.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/fotoperfil',
            name: 'FotoPerfil',
            component: () => import("@/components/clientes/fotoperfil.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/compra',
            name: 'compra',
            component: () => import("@/components/pagina/compra.vue")
        },
        {
            path: '/direccion',
            name: 'DireccionPerfil',
            component: () => import("@/components/clientes/direccion"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/tickets',
            name: 'ListaTickets',
            component: () => import("@/components/clientes/tickets"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }

        },
        {
            path: '/suscripciones',
            name: 'ListaSuscripciones',
            component: () => import("@/components/clientes/suscripciones"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/misqr',
            name: 'MisQr',
            component: () => import("@/components/clientes/misqr"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/qr',
            name: 'ClientesEntrada',
            component: () => import("@/components/clientes/qr"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/entrada',
            name: 'entrada',
            component: () => import("@/components/clientes/entrada")
        },

        {
            path: '/mislinks',
            name: 'mislinks',
            component: () => import("@/components/promotores/mislinks")
        },
        {
            path: '/carnet',
            name: 'ClienteCarnet',
            component: () => import("@/components/clientes/carnet"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/validaqr',
            name: 'ValidaQr',
            component: () => import("@/components/clientes/validaQR")
        },
        {
            path: '/otorgarqr',
            name: 'OtorgarQr',
            component: () => import("@/components/clientes/otorgaqr")
        },
        {
            path: '/tkt/:persona/:codigo',
            name: 'tkt',
            component: () => import("@/components/clientes/tkt")
        },
        {
            path: '/eqr/:codigo',
            name: 'eqr',
            component: () => import("@/components/negocios/externos/elqr.vue"),
            meta: {
                layout: 'CabSdaLayout'
            }
        },
        {
            path: '/micarnet/:codigo',
            name: 'micarnet',
            component: () => import("@/components/negocios/externos/micarnet.vue"),
            meta: {
                layout: 'CabSdaLayout'
            }
        },
        {
            path: '/buscar/carnet',
            name: 'buscacarnet',
            component: () => import("@/components/negocios/externos/buscacarnet.vue"),
            meta: {
                layout: 'CabSdaLayout'
            }
        },
        {
            path: '/qrinvitado',
            name: 'qrinvitado',
            component: () => import("@/components/clientes/qrinvitado"),
            meta: {
                layout: 'CabSdaLayout'
            }
        },
        {
            path: '/cliente/crear',
            name: 'CreaCliente',
            component: () => import("@/components/clientes/creacliente")
        },
        {
            path: '/super/clientes',
            name: 'ClientesSuper',
            component: () => import("@/components/superadmin/clientesman"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/super/puertas',
            name: 'PuertasSuper',
            component: () => import("@/components/superadmin/puertasman"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/super/negocios',
            name: 'NegociosSuper',
            component: () => import("@/components/superadmin/negociosman"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/politicas',
            name: 'politicas',
            component: () => import("@/components/pagina/politicas"),

        },
        {
            path: '/terminos',
            name: 'terminos',
            component: () => import("@/components/pagina/terminos"),

        },

        {
            path: '/fiesta/:id/lista',
            name: 'fiestalista',
            component: () => import("@/components/pagina/fiestalista"),
            meta: {
                title: 'Fiesta - Tunay',
                favicon: 'https://www.tunay.app/favicon.ico'
            }

        },
        {
            path: '/puerta/login',
            name: 'LogPuerta',
            component: () => import("@/components/puerta/logpuerta"),
            meta: {
                title: 'Login Puerta | Tunay',
                layout: 'CabSdaWithOutPieLayout'
            }

        },
        {
            path: '/puerta/ver',
            name: 'VerPuerta',
            component: () => import("@/components/puerta/verpuerta"),
            meta: {
                title: 'Ver Puerta | Tunay',
                layout: 'CabSdaWithOutPieLayout'
            }
        },
        {
            path: '/puerta/cliente',
            name: 'ClientePuerta',
            component: () => import("@/components/puerta/clientepuerta"),
            meta: {
                title: 'Cliente Puerta | Tunay',
                layout: 'CabSdaWithOutPieLayout'
            }

        },
        {
            path: '/dashboard',
            name: 'DashboardNegocio',
            component: () => import("@/components/negocios/administrar/Dashboard.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }

        },
        {
            path: '/inicio',
            name: 'InicioPagina',
            component: () => import("@/components/conectado/Inicio"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/super/dashboard',
            name: 'DashSuperAdmin',
            component: () => import("@/components/superadmin/DashSuperAdmin"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/promotor/listas',
            name: 'ListasPromotor',
            component: () => import("@/components/promotores/listaspromotor"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/puerta/codigos',
            name: 'CodigoPuerta',
            component: () => import("@/components/negocios/administrar/CodigoPuerta.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/promotor/nueva',
            name: 'NuevaListaPromotor',
            component: () => import("@/components/promotores/nuevalistapromotor"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/promotor/codigos',
            name: 'GeneraCodigosPr',
            component: () => import("@/components/promotores/generacodigospr"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/:idnegocio/inscripcion',
            name: 'InscripcionSocio',
            component: () => import("@/components/negocios/formularios/inscripcion.vue"),
            meta: {
                layout: 'WithoutLayout',
            },
        },

        {
            path: '/:idnegocio/membresia',
            name: 'MembresiaSocio',
            component: () => import("@/components/negocios/formularios/membresia.vue"),
            meta: {
                layout: 'WithoutLayout',
            },
        },

        {
            path: '/membresia/omen',
            name: 'membresiaomen',
            component: () => import("@/components/formularios/membresiaomen"),
            meta: {
                layout: 'WithoutLayout',
            },
        },
        {
            path: '/compra',
            name: 'compramembresia',
            component: () => import("@/components/negocios/formularios/compra.vue"),
            meta: {
                layout: 'WithoutLayout',
            },
        },
        {
            path: '/juegos/Ruleta/:tag',
            name: 'ruleta',
            component: () => import("@/components/juegos/Ruleta"),
            meta: {
                layout: 'WithoutLayout',
            },
        },

        {
            path: '/juegos/Ruleta2/:tag',
            name: 'ruleta2',
            component: () => import("@/components/juegos/Ruleta2"),
            meta: {
                layout: 'WithoutLayout',
            },
        },
        {
            path: '/juegos/SlotMachine/:tag',
            name: 'slotmachine',
            component: () => import("@/components/juegos/SlotMachine"),
            meta: {
                layout: 'WithoutLayout',
            },
        },
        {
            path: '/juegos/MemoriaCartas/:tag',
            name: 'memoriacartas',
            component: () => import("@/components/juegos/MemoriaCartas"),
            meta: {
                layout: 'WithoutLayout',
            },
        },

        {
            path: '/adidas/tatuate',
            name: 'adidasTatuate',
            component: () => import("@/components/externos/adidas/tatuate"),
            meta: {
                layout: 'WithoutLayout',
            },
        },

        {
            path: '/adidas/nova',
            name: 'adidasNova',
            component: () => import("@/components/externos/adidas/supernova"),
            meta: {
                layout: 'WithoutLayout',
            },
        },

        {
            path: '/:idnegocio/participa',
            name: 'participacion',
            component: () => import("@/components/negocios/formularios/participa.vue"),
            meta: {
                layout: 'WithoutLayout',
            },
        },
        {
            path: '/:idnegocio/codigoqr',
            name: 'codigoqr',
            component: () => import("@/components/negocios/externos/clubQR.vue"),
            meta: {
                layout: 'WithoutLayout',
            },
        },

        {
            path: '/contactos',
            name: 'ContactoCl',
            component: () => import("@/components/negocios/administrar/contactoscl.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/fqr/:codigo',
            name: 'fiestaqr',
            component: () => import("@/components/pagina/fiestaqr"),
            meta: {
                layout: 'WithoutLayout',
            },
        },
        {
            path: '/mall',
            name: 'mall',
            component: () => import("@/components/pagina/mall")
        },
        {
            path: '/:tagevento/inscribete',
            name: 'eventosinscripcion',
            component: () => import("@/components/eventos/eventosinscripcion"),
            meta: {
                layout: 'WithoutLayout',
            },
        },
        {
            path: '/:tagevento/formulario',
            name: 'eventosformulario',
            component: () => import("@/components/eventos/formulario")
        },

        {
            path: '/incakola',
            name: 'incakola',
            component: () => import("@/components/formularios/incakola"),
            meta: {
                layout: 'WithoutLayout',
            },
        },
        {
            path: '/incakola/qr',
            name: 'incakolaqr',
            component: () => import("@/components/formularios/incakolaqr"),
            meta: {
                layout: 'WithoutLayout',
            },
        },

        {
            path: '/:tagevento/sorteo',
            name: 'eventossorteo',
            component: () => import("@/components/eventos/sorteos")
        },
        {
            path: '/negocios/sorteo',
            name: 'negociossorteo',
            component: () => import("@/components/sorteos/versorteo")
        },
        {
            path: '/negocios/sorteo/:tag',
            name: 'negociossorteotag',
            component: () => import("@/components/sorteos/fiestasorteo")
        },
        {
            path: '/negocios/:tag/sortear',
            name: 'fiestasortear',
            component: () => import("@/components/sorteos/sortear")
        },
        {
            path: '/:tagevento/catalogo/:number',
            name: 'catalogoformulario',
            component: () => import("@/components/eventos/datos")
        },
        {
            path: '/qrevento',
            name: 'QRevento',
            component: () => import("@/components/eventos/LeeQREv"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/lista/:md5promo',
            name: 'lista',
            component: () => import("@/components/listapr/lst"),
            meta: {
                title: 'Link Promotor | Tunay',
                layout: 'CabSdaWithOutPieLayout'
            }
        },
        {
            path: '/lst/:md5promo',
            name: 'lst',
            component: () => import("@/components/listapr/lst"),
            meta: {
                title: 'Link Promotor | Tunay',
                layout: 'CabSdaWithOutPieLayout'
            }
        },
        {
            path: '/lista/reserva/:codigo',
            name: 'listareserva',
            component: () => import("@/components/listapr/listareserva"),
            meta: {
                layout: 'CabSdaWithOutPieLayout'
            }
        },
        {
            path: '/l/:md5promo',
            name: 'listapromotores',
            component: () => import("@/components/listapr/promot"),
            meta: {
                layout: 'CabSdaWithOutPieLayout'
            }
        },
        {
            path: '/lista/email/:md5promo',
            name: 'listamail',
            component: () => import("@/components/listapr/listamail"),
            meta: {
                layout: 'CabSdaWithOutPieLayout'
            }
        },
        {
            path: '/listaqr/:md5promo',
            name: 'listaqr',
            component: () => import("@/components/listapr/lst"),
            meta: {
                layout: 'CabSdaWithOutPieLayout'
            }
        },
        {
            path: '/mislistas/:md5promo',
            name: 'mislistas',
            component: () => import("@/components/listapr/lst"),
            meta: {
                layout: 'CabSdaWithOutPieLayout'
            }
        },
        {
            path: '/fiestas',
            name: 'Fiestas',
            component: () => import("@/components/negocios/administrar/Fiestas.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/disenios/membresia/:idhash',
            name: 'DisenioMembresia',
            component: () => import("@/components/negocios/administrar/disenios/membresia"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },

        {
            path: '/disenios/formularios',
            name: 'DisenioFormularios',
            component: () => import("@/components/negocios/administrar/disenios/formularios"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/super/fiestas',
            name: 'FiestasSuper',
            component: () => import("@/components/superadmin/fiestasneg"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        }
        ,
        {
            path: '/zonas/:idnegocio',
            name: 'Zonas',
            component: () => import("@/components/negocios/administrar/Zonas.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/acto/:tag',
            name: 'acto',
            component: () => import("@/components/pagina/acto")
        },
        {
            path: '/venta/ticket',
            name: 'ventaticket',
            props:true,
            component: () => import("@/components/pagina/ventaticket")
        },
        {
            path: '/carro',
            name: 'carro',
            component: () => import("@/components/pagina/carro")
        },
        {
            path: '/eventos/zonas',
            name: 'ZonaEvento',
            component: () => import("@/components/eventos/ZonaEvento"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/eventos/entradas',
            name: 'TicketEvento',
            component: () => import("@/components/eventos/TicketEvento"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/mesas',
            name: 'Mesas',
            component: () => import("@/components/negocios/administrar/Mesas.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/mapamesas',
            name: 'MapaMesas',
            component: () => import("@/components/negocios/administrar/MapaMesas.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/configuracion',
            name: 'Configuracion',
            component: () => import("@/components/negocios/administrar/Configuracion.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/usuarios',
            name: 'Usuarios',
            component: () => import("@/components/negocios/administrar/Usuarios.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/separacion',
            name: 'Separacion',
            component: () => import("@/components/negocios/administrar/Separacion.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/reserva/fiesta',
            name: 'ReservaFiesta',
            component: () => import("@/components/negocios/administrar/ReservaFiesta.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/listaspromo',
            name: 'Listaspromo',
            component: () => import("@/components/negocios/administrar/Listaspromo.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/marcarlista',
            name: 'Listasmarcar',
            component: () => import("@/components/negocios/administrar/Listasmarcar.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/listasreserva',
            name: 'ListaReserva',
            component: () => import("@/components/negocios/administrar/Listareserva.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/facturas',
            name: 'FacturaNegocio',
            component: () => import("@/components/negocios/administrar/Facturas.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/socios',
            name: 'Socios',
            component: () => import("@/components/negocios/administrar/Socios.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/promotores',
            name: 'Promotor',
            component: () => import("@/components/negocios/administrar/Promotor.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/reportes/puerta',
            name: 'ReportesPuerta',
            component: () => import("@/components/negocios/reportes/Puerta.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/reportes/cumples',
            name: 'ReportesCumples',
            component: () => import("@/components/negocios/reportes/Cumples.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/reportes/asistencia/ranking',
            name: 'RankingAsistencia',
            component: () => import("@/components/negocios/reportes/RankingAsistencias.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/reportes/asistencia/lista',
            name: 'ReportesAsistencia',
            component: () => import("@/components/negocios/reportes/Asistentes.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/reportes/asistencia/reservas',
            name: 'ReservaAsistencia',
            component: () => import("@/components/negocios/reportes/AsistenciaReservas.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/reportes/asistencia/promotor',
            name: 'AsistenciaPromotor',
            component: () => import("@/components/negocios/reportes/AsistenciaPromotor.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/temporadas',
            name: 'Temporadas',
            component: () => import("@/components/negocios/administrar/Temporadas.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/tipolistas',
            name: 'TipoListas',
            component: () => import("@/components/negocios/administrar/TipoLista.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/tipocarnets',
            name: 'TipoCarnets',
            component: () => import("@/components/negocios/administrar/TipoCarnet.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/generacodigo',
            name: 'GeneraCodigo',
            component: () => import("@/components/negocios/administrar/GeneraCodigos.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/paloteo',
            name: 'PaloteoCas',
            component: () => import("@/components/negocios/administrar/PaloteoCas.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/generalista',
            name: 'GeneraLista',
            component: () => import("@/components/negocios/administrar/GeneraLista.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/socio/ver/:idpersona',
            name: 'VerSocio',
            component: () => import("@/components/negocios/administrar/VerSocio.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/eventos/lista',
            name: 'AdministradorEventos',
            component: () => import("@/components/eventos/Eventos.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/juegos/lista',
            name: 'JuegosAdministra',
            component: () => import("@/components/juegos/administrar/Juegos.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/juegos/premios/ruleta/:idjuego',
            name: 'PremiosRuleta',
            component: () => import("@/components/juegos/administrar/PremiosRuleta.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/eventos/reporte/contactos',
            name: 'ReporteContactos',
            component: () => import("@/components/eventos/ReporteContactos"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/eventos/inscritos',
            name: 'Inscritos',
            component: () => import("@/components/eventos/Inscritos"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/juegos/inscritos',
            name: 'JuegosInscritos',
            component: () => import("@/components/juegos/administrar/JuegosInscritos.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/crea/evento/primero',
            name: 'CreaPaso1',
            component: () => import("@/components/eventos/CreaPaso1"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/crea/evento/segundo',
            name: 'CreaPaso2',
            component: () => import("@/components/eventos/CreaPaso2"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/crea/evento/tercero',
            name: 'CreaPaso3',
            component: () => import("@/components/eventos/CreaPaso3"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/crea/juego',
            name: 'CreaPasoJuego',
            component: () => import("@/components/juegos/administrar/CreaJuego.vue"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/eventos/contactos',
            name: 'Contactos',
            component: () => import("@/components/eventos/Contactos"),
            beforeEnter: authMiddleware,
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '*',
            name: 'error',
            component: () => import("@/error/notfound")
        }
    ]
});
router.beforeEach((to, from, next) => {
    // Cambiar el título dinámicamente
    if (to.meta.title) {
        document.title = to.meta.title;
    } else {
        document.title = 'Tunay Mi acceso de bolsillo'; // Título por defecto
    }

    // Cambiar el favicon dinámicamente
    if (to.meta.favicon) {
        const link = document.querySelector("link[rel~='icon']");
        if (link) {
            link.href = to.meta.favicon;
        } else {
            const newLink = document.createElement('link');
            newLink.rel = 'icon';
            newLink.href = to.meta.favicon;
            document.head.appendChild(newLink);
        }
    }

    next();
});
export default router
