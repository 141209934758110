import {axiosData} from "@/store/api/axios";

const state = () => ({
  cliente: JSON.parse(localStorage.getItem('cliente')) || null,
  clientes: JSON.parse(localStorage.getItem('clientes')) || null,
  iseventos: JSON.parse(localStorage.getItem('iseventos')) || null,
  isjuegos: JSON.parse(localStorage.getItem('isjuegos')) || null,
  issorteo: JSON.parse(localStorage.getItem('issorteo')) || null,

});

const getters = {
  getClientes(state) {
    return state.clientes;
  },
  getCliente(state) {
    return state.cliente;
  },
  getIsEventos(state) {
    return state.iseventos;
  },
  getIsJuegos(state) {
    return state.isjuegos;
  },
  getIsSorteo(state) {
    return state.issorteo;
  },
};

const actions = {
  setCliente({ commit }, payload) {
    commit('SET_CLIENTE', payload);
    payload
        ? localStorage.setItem('cliente', JSON.stringify(payload))
        : localStorage.removeItem('cliente');
  },
  setClientes({ commit }, payload) {
    commit('SET_CLIENTES', payload);
    payload
        ? localStorage.setItem('clientes', JSON.stringify(payload))
        : localStorage.removeItem('clientes');
  },
  setIsEventos({ commit }, payload) {
    commit('SET_ISEVENTOS', payload);
    payload
        ? localStorage.setItem('iseventos', JSON.stringify(payload))
        : localStorage.removeItem('iseventos');
  },
  setIsJuegos({ commit }, payload) {
    commit('SET_ISJUEGOS', payload);
    payload
        ? localStorage.setItem('isjuegos', JSON.stringify(payload))
        : localStorage.removeItem('isjuegos');
  },
  async grabarCliente(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Grabando Cliente');
    try {
      const data = await axiosData(
          true,
          'clientes/grabar',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async newCliente(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Crear Cliente');
    try {
      const data = await axiosData(
          true,
          'clientes/new',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async getClientesTodos({ commit }){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Traer Clientes');
    const data = await axiosData(
        true,
        'clientes',
        'get',
        false,
        false
    );
    if (data) this.dispatch('ui/handleLoaderBtn', false);

    if (data.status) return false;

    if (typeof data === 'number') return false;
    if(data){
      commit('SET_CLIENTES', data);
      data
          ? localStorage.setItem('clientes', JSON.stringify(data))
          : localStorage.removeItem('clientes');}
  },
  async nuevoCliente(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Creando Cliente');

    try {
      const data = await axiosData(
          true,
          'clientes/nuevo',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  setIsSorteo({ commit }, payload) {
    commit('SET_ISSORTEO', payload);
    payload
        ? localStorage.setItem('issorteo', JSON.stringify(payload))
        : localStorage.removeItem('issorteo');
  },
};

const mutations = {
  SET_CLIENTE(state, payload) {
    state.cliente = payload;
    localStorage.setItem('cliente', JSON.stringify(payload));
  },
  SET_CLIENTES(state, payload) {
    state.clientes = payload;
    localStorage.setItem('clientes', JSON.stringify(payload));
  },
  SET_ISEVENTOS(state, payload) {
    state.iseventos = payload;
    localStorage.setItem('iseventos', JSON.stringify(payload));
  },
  SET_ISJUEGOS(state, payload) {
    state.isjuegos = payload;
    localStorage.setItem('isjuegos', JSON.stringify(payload));
  },
  SET_ISSORTEO(state, payload) {
    state.issorteo = payload;
    localStorage.setItem('issorteo', JSON.stringify(payload));
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
