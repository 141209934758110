import {axiosData} from "@/store/api/axios";

const state = () => ({
  promotors: JSON.parse(localStorage.getItem('promotors')) || null,
  promotor: JSON.parse(localStorage.getItem('promotor')) || null,
  promotorlinks: JSON.parse(localStorage.getItem('promotorlinks')) || null,
});

const getters = {
  getPromotors(state) {
    return state.promotors;
  },
  getPromotor(state) {
    return state.promotor;
  },
  getPromotorLinks(state) {
    return state.promotorlinks;
  },
};

const actions = {
  setPromotor({ commit }, payload) {
    commit('SET_PROMOTOR', payload);
    payload
        ? localStorage.setItem('promotor', JSON.stringify(payload))
        : localStorage.removeItem('promotor');
  },
  setPromotors({ commit }, payload) {
    commit('SET_PROMOTORS', payload);
    payload
        ? localStorage.setItem('promotors', JSON.stringify(payload))
        : localStorage.removeItem('promotors');
  },
  setPromotorsLinks({ commit }, payload) {
    commit('SET_PROMOTORSLINKS', payload);
    payload
        ? localStorage.setItem('promotorlinks', JSON.stringify(payload))
        : localStorage.removeItem('promotorlinks');
  },
  async getCoordinadoresByLider(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Coordinadores por Lider');

    try {
      const data = await axiosData(
          true,
          'promotores/coordinadores/lider/'+tag,
          'get',
          false,
          false
      );

      if(data!== 404 && data !== null && data !== undefined && data !== 400) {
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getPromxLideresByPromotor(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Promotores por Lider');

    try {
      const data = await axiosData(
          true,
          'promotores/promsxlider/'+tag,
          'get',
          false,
          false
      );

      if(data!== 404 && data !== null && data !== undefined && data !== 400) {
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getPromxCoordinadoresByPromotor(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Promotores por Coordinador');

    try {
      const data = await axiosData(
          true,
          'promotores/promsxcoord/'+tag,
          'get',
          false,
          false
      );

      if(data!== 404 && data !== null && data !== undefined && data !== 400) {
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getLinksPromotoresEmail(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/email/'+tag,
          'get',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('promotores/setPromotorsLinks', data);
        this.dispatch('business/setNegocioPromotor', data[0]);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async damePromotorNegociosFiestasTodas(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Fiestas');

    try {
      const data = await axiosData(
          true,
          'promotores/fiestas/todas',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getPromMaximo(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Maximos');

    try {
      const data = await axiosData(
          true,
          'promotores/maximos',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getPromxCabezasByPromotor(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Cabezas');

    try {
      const data = await axiosData(
          true,
          'promotores/promsxcabeza/'+tag,
          'get',
          false,
          false
      );

      if(data!== 404 && data !== null && data !== undefined && data !== 400) {
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getLideresByNegocio(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Lideres');

    try {
      const data = await axiosData(
          true,
          'promotores/lideres/'+tag,
          'get',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getCoordinadoresByNegocio(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Coordinadores');

    try {
      const data = await axiosData(
          true,
          'promotores/coordinadores/'+tag,
          'get',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getCabezasByNegocio(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Cabezas de Promotor');

    try {
      const data = await axiosData(
          true,
          'promotores/cabezas/'+tag,
          'get',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getCabezasByPromotor(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Cabezas de Promotor');

    try {
      const data = await axiosData(
          true,
          'promotores/cabezasxcoord/'+tag,
          'get',
          false,
          false
      );

      if(data!== 404 && data !== null && data !== undefined && data !== 400) {
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async grabaPromotor(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Grabando Promotor');

    try {
      await axiosData(
          true,
          'promotores/grabar',
          payload,
          'post',
          false,
          false
      );


        this.dispatch('ui/handleLoaderBtn', false);

    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getPromotoresByNegocio(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Promotores');

    try {
      const data = await axiosData(
          true,
          'promotores/negocio/lista/'+tag,
          'get',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getPromotoresByNegocioBase(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Promotores');

    try {
      const data = await axiosData(
          true,
          'promotores/negocio',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async editarPromotores(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/editar',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async grabaListasGlobalCrearPromotores(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/todos/crearpromotores',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async grabaListasGlobalVerReportes(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/todos/verreporte',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async grabaListasGlobalPromotores(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/todos/listas',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async grabaDiasGlobalPromotores(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/todos/dias',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async grabaCantGlobalPromotores(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/todos/listas/cant',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async grabaActGlobalPromotores(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/todos/listas/act',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getAllPromotorNegociosa(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/lista',
          payload,
          'post',
          false,
          false
      );

      if(data!== 404 && data !== null && data !== undefined && data !== 400) {
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getTipoListasByPromotorActualiza(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Grabando Tipo de Lista');

    try {
      const data = await axiosData(
          true,
          'promotortipolistas/actualiza',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getTipoListasByPromotor(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Trayendo Tipos de Lista');

    try {
      const data = await axiosData(
          true,
          'promotortipolistas/promotor/'+tag,
          'get',
          false,
          false
      );

      if(data!== 404 && data !== null && data !== undefined && data !== 400) {
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getFiestasByPromotor(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/fiestas/'+tag,
          'get',
          false,
          false
      );

      if(data !== null && data !== undefined && data !== 400 && data !== 404){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getTipoListaByPromotorTodos(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotortipolistas/promotor/todos/'+tag,
          'get',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getTipoListaByPromotorId(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotortipolistas/promotor/'+tag,
          'get',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getPromotoresByNegocioTag(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/negocio/'+tag,
          'get',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return [];
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getPromotorByHash(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'promotores/lista/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
};

const mutations = {
  SET_PROMOTORS(state, payload) {
    state.promotors = payload;
    localStorage.setItem('promotors', JSON.stringify(payload));
  },
  SET_PROMOTORSLINKS(state, payload) {
    state.promotorlinks = payload;
    localStorage.setItem('promotorlinks', JSON.stringify(payload));
  },
  SET_PROMOTOR(state, payload) {
    state.promotor = payload;
    localStorage.setItem('promotor', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
