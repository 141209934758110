import {axiosData} from "@/store/api/axios";

const state = () => ({
  reservas: JSON.parse(localStorage.getItem('reservas')) || null,
  reserva: JSON.parse(localStorage.getItem('reserva')) || null,

});

const getters = {
  getReservas(state) {
    return state.reservas;
  },
  getReserva(state) {
    return state.reserva;
  },
};

const actions = {
  setReserva({ commit }, payload) {
    commit('SET_RESERVA', payload);
    payload
        ? localStorage.setItem('reserva', JSON.stringify(payload))
        : localStorage.removeItem('reserva');
  },
  setReservas({ commit }, payload) {
    commit('SET_RESERVAS', payload);
    payload
        ? localStorage.setItem('reservas', JSON.stringify(payload))
        : localStorage.removeItem('reservas');
  },
  async getReservasByListasFiesta(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'reservas/listas/fiesta/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async getReservasByFiesta(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Reservas');

    try {
      const data = await axiosData(
          true,
          'reservas/fiesta/'+id,
          'get',
          false,
          false
      );

      if(data && data!== 404 && data !==400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);

      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async getReservasByXFiesta(_,codigo){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'reservas/xfiesta/'+codigo,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async getReservasByNegocio(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'reservas/negocio/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async getReservasByFiestasMaximos(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'reservas/xfiesta/maximos/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async borrarReservasFiesta(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'reservas/borrar',
          payload,
          'delete',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async saveReservasFiesta(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'reservas/fiesta/editar',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async saveReservas(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'reservas/editar',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }}
};

const mutations = {
  SET_RESERVAS(state, payload) {
    state.reservas = payload;
    localStorage.setItem('reservas', JSON.stringify(payload));
  },
  SET_RESERVA(state, payload) {
    state.reserva = payload;
    localStorage.setItem('reserva', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
