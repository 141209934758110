import {axiosData} from "@/store/api/axios";

const state = () => ({
  fiestas: JSON.parse(localStorage.getItem('fiestas')) || null,
  fiesta: JSON.parse(localStorage.getItem('fiesta')) || null,

});

const getters = {
  getFiestas(state) {
    return state.fiestas;
  },
  getFiesta(state) {
    return state.fiesta;
  },
};

const actions = {
  setFiesta({ commit }, payload) {
    commit('SET_FIESTA', payload);
    payload
        ? localStorage.setItem('fiesta', JSON.stringify(payload))
        : localStorage.removeItem('fiesta');
  },
  setFiestas({ commit }, payload) {
    commit('SET_FIESTAS', payload);
    payload
        ? localStorage.setItem('fiestas', JSON.stringify(payload))
        : localStorage.removeItem('fiestas');
  },
  async getActoByTag(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'actos/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('fiestas/setFiesta',data);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getFiestaByTag(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'actos/fiesta/'+tag,
          'get',
          false,
          false
      );

      if(data !== 404 && data !== null && data!== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('fiestas/setFiesta',data);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
          return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getFiestasPromotorHash(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'actos/fiestas/promotor/'+tag,
          'get',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getActoByNegocioTag(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Fiestas');

    try {
      const data = await axiosData(
          true,
          'actos/fiestas/negociotag/'+tag,
          'get',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },

  async grabarFiesta(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'actos/fiestas/grabar',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('fiestas/setFiestas',data);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async subirFlyer(_, payload) {
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const bodyFormData = new FormData();
      bodyFormData.append("file", payload.imagen);
      let url = "actos/fiestas/flyer/" + payload.id;
      const data = await axiosData(
          true,
          url,
          bodyFormData,
          'post',
          false,
          false
      );

      if (data) {
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Upload flyer failed, error: ${error}`);
    }
  },
  async subirLogoQr(_, payload) {
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const bodyFormData = new FormData();
      bodyFormData.append("file", payload.imagen);
      let url = "actos/fiestas/logoqr/" + payload.id;
      const data = await axiosData(
          true,
          url,
          bodyFormData,
          'post',
          false,
          false
      );

      if (data) {
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Upload flyer failed, error: ${error}`);
    }
  },
  async borrarActoxTag(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'actos/fiestas/'+tag,
          null,
          'delete',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('fiestas/setFiestas',data);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getActosByNegocioTag(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'actos/fiestas/negociotag/'+tag+'/all',
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('fiestas/setFiestas',data);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getTemporadaActivaByNegocioId(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'temporadas/negocio/activa/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },


};

const mutations = {
  SET_FIESTAS(state, payload) {
    state.fiestas = payload;
    localStorage.setItem('fiestas', JSON.stringify(payload));
  },
  SET_FIESTA(state, payload) {
    state.fiesta = payload;
    localStorage.setItem('fiesta', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
