import {axiosData} from "@/store/api/axios";

const state = () => ({
  actos: JSON.parse(localStorage.getItem('actos')) || null,
  acto: JSON.parse(localStorage.getItem('acto')) || null
});

const getters = {
  getActos: (state) => state.actos,
  getActo: (state) => state.acto,
};

const actions = {
  setActo({ commit }, payload) {
    commit('SET_ITEM', { key: 'acto', payload });
  },
  setActos({ commit }, payload) {
    commit('SET_ITEM', { key: 'actos', payload });
  },
  async getAllActos(){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'actos',
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async getAllEventosActivasxEs(){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Actos');
    try {
      const data = await axiosData(
          true,
          'actos/eventos/activos',
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('eventos/setEventos',data);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async getAllFiestasActivasxEs(){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Fiestas');
    try {
      const data = await axiosData(
          true,
          'actos/fiestas/activas',
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('fiestas/setFiestas',data);

        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
};

const mutations = {
  SET_ITEM(state, { key, payload }) {
    state[key] = payload;
    if (payload) {
      localStorage.setItem(key, JSON.stringify(payload));
    } else {
      localStorage.removeItem(key);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
