import {axiosData} from "@/store/api/axios";

const state = () => ({
  inscripciones: JSON.parse(localStorage.getItem('inscripciones')) || null,
  inscripcion: JSON.parse(localStorage.getItem('inscripcion')) || null,

});

const getters = {
  getInscripciones(state) {
    return state.inscripciones;
  },
  getInscripcion(state) {
    return state.inscripcion;
  },
};

const actions = {
  setInscripcion({ commit }, payload) {
    commit('SET_INSCRIPCION', payload);
    payload
        ? localStorage.setItem('inscripcion', JSON.stringify(payload))
        : localStorage.removeItem('inscripcion');
  },
  setInscripciones({ commit }, payload) {
    commit('SET_INSCRIPCIONES', payload);
    payload
        ? localStorage.setItem('inscripciones', JSON.stringify(payload))
        : localStorage.removeItem('inscripciones');
  },
  async getAllJuegosInscripcions(_,id){
    try {
      const data = await axiosData(
          false,
          'inscripciones/juego/'+id,
          'get',
          false,
          false
      );
      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }


    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Error Comunicacion con Tunay, error: ${error}`);
    }
  },

  async registrarInscripcion(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'inscripciones/registrar',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
};

const mutations = {
  SET_INSCRIPCIONES(state, payload) {
    state.inscripciones = payload;
    localStorage.setItem('inscripciones', JSON.stringify(payload));
  },
  SET_INSCRIPCION(state, payload) {
    state.inscripcion = payload;
    localStorage.setItem('inscripcion', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
