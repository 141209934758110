import {axiosData} from "@/store/api/axios";

const state = () => ({
  membresias: JSON.parse(localStorage.getItem('membresias')) || null,
  membresia: JSON.parse(localStorage.getItem('membresia')) || null,

});

const getters = {
  getMembresias(state) {
    return state.membresias;
  },
  getMembresia(state) {
    return state.membresia;
  },
};

const actions = {
  setMembresia({ commit }, payload) {
    commit('SET_MEMBRESIA', payload);
    payload
        ? localStorage.setItem('membresia', JSON.stringify(payload))
        : localStorage.removeItem('membresia');
  },
  setMembresias({ commit }, payload) {
    commit('SET_MEMBRESIAS', payload);
    payload
        ? localStorage.setItem('membresias', JSON.stringify(payload))
        : localStorage.removeItem('membresias');
  },
  async findMembresiaByTemporadaSearch(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'membresia/buscacarnet',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async saveMembresiaCrear(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'membresia/crear',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async changeTipoCarnet(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'membresia/cambiatipocarnet',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async changeStateMembresia(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'membresia/cambiaestado',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async borraMembresia(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      await axiosData(
          true,
          'membresia/'+id,
          'delete',
          false,
          false
      );

        this.dispatch('ui/handleLoaderBtn', false);


    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async getSuscritionByTemporada(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'membresia/temporada/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async getSuscritionByCode(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'membresia/codigo/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async getSuscritionsByPerson(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      let pagina = payload.page - 1;

      const data = await axiosData(
          true,
          'membresia/suscripciones/'+payload.id+'?page='+pagina+'&size=6',
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }}
};

const mutations = {
  SET_MEMBRESIAS(state, payload) {
    state.membresias = payload;
    localStorage.setItem('membresias', JSON.stringify(payload));
  },
  SET_MEMBRESIA(state, payload) {
    state.ismembresias = payload;
    localStorage.setItem('membresia', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
