import {axiosData} from "@/store/api/axios";

const state = () => ({
  disenios: JSON.parse(localStorage.getItem('disenios')) || null,
  disenio: JSON.parse(localStorage.getItem('disenio')) || null,

});

const getters = {
  getDisenios(state) {
    return state.disenios;
  },
  getDisenio(state) {
    return state.disenio;
  },
};

const actions = {
  setDisenio({ commit }, payload) {
    commit('SET_DISENIO', payload);
    payload
        ? localStorage.setItem('disenio', JSON.stringify(payload))
        : localStorage.removeItem('disenio');
  },
  setDisenios({ commit }, payload) {
    commit('SET_DISENIOS', payload);
    payload
        ? localStorage.setItem('disenios', JSON.stringify(payload))
        : localStorage.removeItem('disenios');
  },
  async getDiseniosMembresiaNegocioByTag(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'negociodisenio/membresia/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async getDiseniosEventoByTag(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'disenio/evento/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},
  async getDiseniosJuegosByTag(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'disenio/juego/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }},

};

const mutations = {
  SET_DISENIOS(state, payload) {
    state.disenios = payload;
    localStorage.setItem('disenios', JSON.stringify(payload));
  },
  SET_DISENIO(state, payload) {
    state.disenio = payload;
    localStorage.setItem('disenio', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
