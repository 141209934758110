import {axiosData} from "@/store/api/axios";

const state = () => ({
  juegos: JSON.parse(localStorage.getItem('juegos')) || null,
  juego: JSON.parse(localStorage.getItem('juego')) || null,

});

const getters = {
  getJuegos(state) {
    return state.juegos;
  },
  getJuego(state) {
    return state.juego;
  },
};

const actions = {
  setJuego({ commit }, payload) {
    commit('SET_JUEGO', payload);
    payload
        ? localStorage.setItem('juego', JSON.stringify(payload))
        : localStorage.removeItem('juego');
  },
  async setJuegoPremios(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'juegos/premios/grabar',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async setJuegoEdit(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'juegos/grabar',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async nuevoJuego(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'juegos/nuevo',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getAllByTag(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'juegos/verifica/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async dameRuletaPremio(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'juegos/ruleta/premio/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getAllJuegosStocks(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'juegos/stocks/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getAllJuegosPremios(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'juegos/premios/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getAllJuegosMemoriaItems(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'juegos/memoriacartas/items/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async getAllJuegosUltimosGanadores(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'juegos/ganadores/ultimos/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  async dameJuegos(_,clienteId){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'juegos/cliente/'+clienteId,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      await this.dispatch('session/muestraErrores',error)
    }
  },
  setJuegos({ commit }, payload) {
    commit('SET_JUEGOS', payload);
    payload
        ? localStorage.setItem('juegos', JSON.stringify(payload))
        : localStorage.removeItem('juegos');
  },
};

const mutations = {
  SET_JUEGOS(state, payload) {
    state.juegos = payload;
    localStorage.setItem('juegos', JSON.stringify(payload));
  },
  SET_JUEGO(state, payload) {
    state.juego = payload;
    localStorage.setItem('juego', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
